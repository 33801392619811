import { put, takeEvery, call } from 'redux-saga/effects';
import { jwtDecode } from 'jwt-decode';
import { AUTH, postAuthAction } from '../actions/authAction';
import postAuthService from '../services/authService';
import { getInfosFromToken } from '../../utils/utils';
import { getUsersAction } from '../actions/userAction';

export function* postAuth({ payload }) {
  try {
    const token = yield call(postAuthService, payload);
    if (token) {
      const { access_token: accessToken, refresh_token: refreshToken } = token;
      localStorage.setItem('authToken', accessToken);
      localStorage.setItem('refreshToken', refreshToken);
      yield put(postAuthAction.success(token));
      const decodedToken = jwtDecode(accessToken);
      const { role, userId } = getInfosFromToken(decodedToken);
      yield put(getUsersAction.success({ role, userId }));
    }
  } catch (err) {
    yield put(postAuthAction.failure(err));
  }
}

export function* postAuthSaga() {
  yield takeEvery(AUTH.POST.REQUEST, postAuth);
}
