import { call, put, takeEvery } from 'redux-saga/effects';
import {
  ANALYSIS, ANALYSISDETAILS, getAnalysisAction, getAnalysisDetailsAction,
} from '../actions/analysisAction';
import { getAnalysisByUserService, getAnalysisService } from '../services/analysisService';

export const selectGetAnalysisService = (role) => (
  role !== 1 ? getAnalysisByUserService : getAnalysisService
);

export function* getAnalysis({ payload }) {
  const { token, role } = payload;
  const service = selectGetAnalysisService(role);
  try {
    const analysis = yield call(service, token);
    const data = analysis?.data;
    if (data) {
      yield put(getAnalysisAction.success(data));
    } else yield put(getAnalysisAction.error("Pas d'analyses disponibles"));
  } catch (err) {
    yield put(getAnalysisAction.failure(err));
  }
}

const getAgencyDetailsByName = (detailsByAgency, agencyName) => {
  let agencyExist = detailsByAgency.find((detailAgency) => detailAgency.name === agencyName);

  if (!agencyExist) {
    agencyExist = {
      name: agencyName,
      details: [],
    };
    detailsByAgency.push(agencyExist);
  }
  return agencyExist;
};

const getMobileDetails = (agencyData, telephoneNumber) => {
  let mobile = agencyData.details.find((detail) => detail.telephoneNumber === telephoneNumber);

  if (!mobile) {
    mobile = {
      telephoneNumber,
      leak: [],
      noLeak: [],
      qualificationOK: [],
      qualificationNOK: [],
      noSignal: [],
    };
    agencyData.details.push(mobile);
  }
  return mobile;
};

const processDecision = (mobile, decision, qualification, timeStamp) => {
  if (decision === 1) {
    mobile.leak.push(timeStamp);
  } else {
    mobile.noLeak.push(timeStamp);
  }

  if (qualification === decision) {
    mobile.qualificationOK.push(timeStamp);
  } else {
    mobile.qualificationNOK.push(timeStamp);
  }
};

const calculateNoSignal = (mobile, recordsList, analysis) => {
  const allRecordsList = recordsList
    .filter((record) => record.numberMobile === mobile.telephoneNumber);

  const noSignal = allRecordsList
    .map((record) => record.timeStamp)
    .filter((timestamp) => !analysis.some((item) => item.timeStamp === timestamp));

  return {
    ...mobile,
    noSignal,
  };
};

const getAgencyAnalysis = (analysis, recordsList) => {
  const detailsByAgency = [];

  analysis?.forEach((analyse) => {
    const {
      agencyName, telephoneNumber, decision, qualification, timeStamp,
    } = analyse;

    const agencyData = getAgencyDetailsByName(detailsByAgency, agencyName);
    const mobile = getMobileDetails(agencyData, telephoneNumber);

    processDecision(mobile, decision, qualification, timeStamp);
    calculateNoSignal(mobile, recordsList, analysis);
  });

  return detailsByAgency;
};

export function* getAnalysisDetails({ payload }) {
  const { analysis, recordsList } = payload;

  try {
    const agencyDetails = getAgencyAnalysis(analysis, recordsList);
    yield put(getAnalysisDetailsAction.success(agencyDetails));
  } catch (error) {
    yield put(getAnalysisDetailsAction.failure(error));
  }
}

export function* analysisSaga() {
  yield takeEvery(ANALYSISDETAILS.FETCH.REQUEST, getAnalysisDetails);
  yield takeEvery(ANALYSIS.FETCH.REQUEST, getAnalysis);
}
