import { PlusOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

const UploadButton = () => {
  const { t } = useTranslation();
  return (
    <button
      className="button-reset"
      type="button"
    >
      <PlusOutlined />
      <div>
        {t('shared.uploadButton.title')}
      </div>
    </button>
  );
};

export default UploadButton;
