import { put, takeEvery, call } from 'redux-saga/effects';
import postRefreshService from '../services/refreshService';
import { postRefreshAction, REFRESH } from '../actions/authAction';

export function* postRefresh({ payload }) {
  try {
    const token = yield call(postRefreshService, payload);
    if (token) {
      localStorage.setItem('authToken', token.access_token);
      localStorage.setItem('refreshToken', token.refresh_token);
      yield put(postRefreshAction.success(token));
    }
  } catch (err) {
    yield put(postRefreshAction.failure(err));
  }
}

export function* postRefreshSaga() {
  yield takeEvery(REFRESH.POST.REQUEST, postRefreshService);
}
