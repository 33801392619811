import { call, put, takeEvery } from 'redux-saga/effects';
import {
  PHONENUMBER, PHONENUMBERSTATUS, PHONESNUMBER,
  getCalibratedPhonesAction,
  getPhoneNumberAction,
  getPhonesNumberAction,
  patchPhoneNumberAction,
  patchPhoneNumberStatusAction,
  postPhoneNumberAction,
} from '../actions/mobileAction';
import {
  getPhoneNumberService,
  getPhonesNumberByUserService,
  getPhonesNumberService,
  patchPhoneNumberService,
  patchPhoneNumberStatusService,
  postMobileService,
} from '../services/mobileService';
import { getOrdersService } from '../services/orderService';
import { calibrationCount } from '../../utils/utils';
import { getOrdersAction } from '../actions/orderAction';

export const selectGetPhonesNumberService = (role) => (
  role !== 1 ? getPhonesNumberByUserService : getPhonesNumberService
);

export function* postPhoneNumber({ payload }) {
  const { token, role } = payload;
  const getService = selectGetPhonesNumberService(role);
  try {
    const mobileResponse = yield call(postMobileService, payload);
    const mobiles = yield call(getService, token);

    if (mobileResponse) {
      const descriptionSuccess = 'shared.notification.mobile.successAddMobile';
      yield put(getPhonesNumberAction.success(mobiles.data));
      yield put(postPhoneNumberAction.success(descriptionSuccess));
      yield put(postPhoneNumberAction.failure(null));
    }
  } catch (err) {
    yield put(postPhoneNumberAction.failure(err));
  }
}

export function* getPhonesNumber({ payload }) {
  const { agencies, token, role } = payload;
  const service = selectGetPhonesNumberService(role);
  try {
    const mobiles = yield call(service, token);
    const data = mobiles?.data;

    if (data && data.length > 0) {
      yield put(getPhonesNumberAction.success(data));
    }

    if (data && agencies) {
      const totalCalibratedPhones = calibrationCount(data);
      const totalCalibratedInProcess = calibrationCount(data, 'inProcess');
      yield put(getCalibratedPhonesAction.success({
        calibrated: totalCalibratedPhones,
        inProcess: totalCalibratedInProcess,
      }));
    } else {
      yield put(getPhonesNumberAction.error('Pas de numéros de mobiles enregistrés'));
    }
  } catch (err) {
    yield put(getPhonesNumberAction.failure(err));
  }
}

export function* getPhoneNumber({ payload }) {
  const { telephoneNumber, token } = payload;
  try {
    const phoneNumber = yield call(getPhoneNumberService, telephoneNumber, token);
    const data = phoneNumber?.data;

    if (data) {
      yield put(getPhoneNumberAction.error(true));
    }
  } catch (err) {
    yield put(getPhoneNumberAction.failure(err));
    yield put(getPhoneNumberAction.success(false));
  }
}

export function* patchPhoneNumber({ payload }) {
  const { token } = payload;
  try {
    const phoneNumberResponse = yield call(patchPhoneNumberService, payload);
    if (phoneNumberResponse) {
      const descriptionSuccess = 'shared.notification.mobile.successUpdate';
      const mobiles = yield call(getPhonesNumberService, token);
      const orders = yield call(getOrdersService, token);

      yield put(getOrdersAction.success(orders.data));
      yield put(getPhonesNumberAction.success(mobiles.data));
      yield put(patchPhoneNumberAction.success(descriptionSuccess));
    }
  } catch (error) {
    yield put(patchPhoneNumberAction.failure(error));
  }
}

export function* patchPhoneNumberStatus({ payload }) {
  const { token } = payload;
  try {
    const phoneNumberResponse = yield call(patchPhoneNumberStatusService, payload);
    if (phoneNumberResponse) {
      const mobiles = yield call(getPhonesNumberService, token);
      const orders = yield call(getOrdersService, token);

      yield put(getOrdersAction.success(orders.data));
      yield put(getPhonesNumberAction.success(mobiles.data));
      yield put(patchPhoneNumberStatusAction.success('shared.notification.mobileStatus.successUpdate'));
    }
  } catch (error) {
    yield put(patchPhoneNumberStatusAction.failure(error));
  }
}

export function* phoneNumberSaga() {
  yield takeEvery(PHONESNUMBER.FETCH.REQUEST, getPhonesNumber);
  yield takeEvery(PHONENUMBER.FETCH.REQUEST, getPhoneNumber);
  yield takeEvery(PHONENUMBER.POST.REQUEST, postPhoneNumber);
  yield takeEvery(PHONENUMBER.PATCH.REQUEST, patchPhoneNumber);
  yield takeEvery(PHONENUMBERSTATUS.PATCH.REQUEST, patchPhoneNumberStatus);
}
