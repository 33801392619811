import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import EntityListTemplate from '../shared/list/EntityListTemplate';
import { getAgenciesAction, patchAgencyAction } from '../../business/actions/agencyAction';

const AgenciesList = () => {
  const { companies } = useSelector((state) => state.companies);
  const { agencies } = useSelector((state) => state.agencies);
  const { t } = useTranslation();

  const companyNameColumn = {
    title: t('dashboard.agency.company'),
    dataIndex: 'company',
    key: 'company',
    render: (text) => companies?.map((company) => {
      const { id, name } = company;
      return text === id && name;
    }),
    responsive: ['md', 'lg'],
    width: 70,
  };
  return (
    <EntityListTemplate
      type="agency"
      entity={agencies}
      addColumn={companyNameColumn}
      patchAction={patchAgencyAction}
      updateAction={getAgenciesAction}
    />
  );
};
export default AgenciesList;
