import { Form } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import FormTemplate from './FormTemplate';
import { getAgenciesAction } from '../../../business/actions/agencyAction';
import { getCompaniesAction } from '../../../business/actions/companyAction';
import { getDistributorsAction } from '../../../business/actions/distributorAction';
import {
  regexEmail,
  preventInvalidNumberInput,
} from '../../../utils/utils';
import { AuthContext } from '../../../utils/context/AuthContext';
import { getUsersAction } from '../../../business/actions/userAction';

const EntityFormTemplate = ({
  postAction,
  type,
  className,
  fileList,
  setFileList,
  companyField = [],
  distributorField = [],
  uploadFileField = [],
}) => {
  const { distributors } = useSelector((state) => state.distributors);
  const { agencies } = useSelector((state) => state.agencies);
  const { companies } = useSelector((state) => state.companies);
  const { users } = useSelector((state) => state.users);
  const [emails, setEmails] = useState([]);
  const [newEmail, setNewEmail] = useState(null);
  const [isDisabledEmailBtn, setIsDisabledEmailBtn] = useState(true);

  const { token, role } = useContext(AuthContext);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    if (type === 'agency') dispatch(getAgenciesAction.request({ token, role }));
    if (type === 'company' || type === 'agency') dispatch(getCompaniesAction.request({ token, role }));
    if (type === 'distributor' || type === 'company') dispatch(getDistributorsAction.request({ token, role }));
    if (role === 1) dispatch(getUsersAction.request({ token }));
  }, []);

  const onFinish = (data) => {
    const {
      name, dailyMailHour, dailyMailMinute, company, distributor,
    } = data;
    const isCompany = type === 'company';
    const isDistributor = type === 'distributor';

    const companyId = company ? { companyId: company } : {};
    const distributorId = isCompany ? { distributorId: distributor } : {};
    const uploadFile = isDistributor ? { logoImage: fileList[0]?.thumbUrl } : {};

    const payload = {
      name,
      emails,
      token,
      role,
      dailyMailHour,
      dailyMailMinute,
      ...companyId,
      ...distributorId,
      ...uploadFile,
    };

    dispatch(postAction.request(payload));
    form.resetFields();
    setEmails([]);
    setFileList([]);
    return Promise.resolve();
  };

  const handleAddEmail = () => {
    if (newEmail) {
      setEmails([...emails, newEmail]);
      setNewEmail(null);
      setIsDisabledEmailBtn(true);
      form.resetFields(['emails']);
    }
  };

  const checkExistenceEmail = (value) => {
    if (!value) {
      setNewEmail(null);
      form.resetFields(['emails']);
      setIsDisabledEmailBtn(true);
      return Promise.resolve();
    }

    const isValideEmail = regexEmail.test(value);
    const isEmailTagExist = emails.some(
      (email) => email.toLowerCase() === value.toLowerCase(),
    );
    const isEmailExistInDB = users?.some(
      (user) => user.email.toLowerCase() === value.toLowerCase(),
    );

    if (!isValideEmail) {
      setIsDisabledEmailBtn(true);
      return Promise.reject(new Error(t('settings.entity.emailHelp')));
    }

    if (isEmailTagExist || isEmailExistInDB) {
      setIsDisabledEmailBtn(true);
      return Promise.reject(new Error(t('settings.entity.emailError')));
    }

    setIsDisabledEmailBtn(false);
    setNewEmail(value);
    return Promise.resolve();
  };

  const checkExistenceName = (value) => {
    const typeCollections = {
      agency: agencies,
      company: companies,
      distributor: distributors,
    };

    const collection = typeCollections[type];

    const isExist = collection?.some(
      (item) => item.name.toLowerCase() === value?.toLowerCase(),
    );

    return isExist ? Promise.reject(new Error(t(`settings.${type}.nameError`))) : Promise.resolve();
  };

  const variables = [
    ...distributorField,
    {
      label: t('settings.entity.name'),
      name: 'name',
      rules: [
        {
          required: true,
          message: t(`settings.${type}.nameHelp`),
        },
        () => ({
          validator(_, value) {
            return checkExistenceName(value);
          },
        }),
      ],
      placeholder: t(`settings.${type}.placeholderName`),
    },
    ...uploadFileField,
    ...companyField,
    {
      label: t('settings.entity.emails'),
      name: 'emails',
      type: 'addItem',
      list: emails,
      isDisabledBtn: isDisabledEmailBtn,
      setList: setEmails,
      onClick: handleAddEmail,
      rules: [
        () => ({
          validator(_, value) {
            return checkExistenceEmail(value);
          },
        }),
      ],
      placeholder: t('settings.entity.placeholderEmail'),
    },
    {
      label: t('settings.entity.hour'),
      name: 'dailyMailHour',
      type: 'number',
      min: 0,
      max: 23,
      onBeforeInput: (event) => preventInvalidNumberInput(event, 0, 23),
      maxLength: 2,
      rules: [
        {
          required: true,
          message: t('settings.entity.hourHelp'),
        },
      ],
      placeholder: t('settings.entity.placeholderHour'),
    },
    {
      label: t('settings.entity.minute'),
      name: 'dailyMailMinute',
      type: 'number',
      min: 0,
      max: 59,
      onBeforeInput: (event) => preventInvalidNumberInput(event, 0, 59),
      maxLength: 2,
      rules: [
        {
          required: true,
          message: t('settings.entity.minuteHelp'),
        },
      ],
      placeholder: t('settings.entity.placeholderMinute'),
    },
  ];

  return (
    <FormTemplate
      nameForm="entity-form-template"
      className={`settings-form ${className}`}
      useForm={form}
      variables={variables}
      buttonValidText={t('shared.formTemplate.submit')}
      onFinishApiCall={onFinish}
    />
  );
};

EntityFormTemplate.propTypes = {
  postAction: PropTypes.shape({
    request: PropTypes.func.isRequired,
  }).isRequired,
  companyField: PropTypes.arrayOf(PropTypes.shape({})),
  distributorField: PropTypes.arrayOf(PropTypes.shape({})),
  uploadFileField: PropTypes.arrayOf(PropTypes.shape({})),
  type: PropTypes.string.isRequired,
  fileList: PropTypes.arrayOf(PropTypes.shape({
    thumbUrl: PropTypes.string,
  })).isRequired,
  setFileList: PropTypes.func,
  className: PropTypes.string,
};

EntityFormTemplate.defaultProps = {
  className: '',
  companyField: [],
  distributorField: [],
  uploadFileField: [],
  setFileList: () => {},
};

export default EntityFormTemplate;
