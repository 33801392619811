import { AUTH, REFRESH } from '../actions/authAction';

const INITIAL_STATE = { token: null, loading: true, message: null };

const authReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case AUTH.POST.REQUEST:
    case REFRESH.POST.REQUEST:
      return { ...state, loading: true };
    case AUTH.POST.SUCCESS:
    case REFRESH.POST.SUCCESS:
      return {
        ...state, token: payload, loading: false, message: null,
      };
    case AUTH.POST.FAILURE:
    case REFRESH.POST.FAILURE:
      return {
        ...state, token: null, message: payload, loading: false,
      };
    default:
      return state;
  }
};

export default authReducer;
