import {
  call, put, takeEvery,
} from 'redux-saga/effects';
import { getSoundsService } from '../services/soundsService';
import { SOUNDS, getSoundsAction } from '../actions/soundsAction';

export function* getSounds() {
  try {
    const soundsList = yield call(getSoundsService);
    const data = soundsList?.data;

    if (data) {
      yield put(getSoundsAction.success(data));
    }
  } catch (err) {
    yield put(getSoundsAction.failure(err));
  }
}

export function* getSoundsSaga() {
  yield takeEvery(SOUNDS.FETCH.REQUEST, getSounds);
}
