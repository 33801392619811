import { Form, notification } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useContext, useEffect, useState } from 'react';
import { MailOutlined, MobileOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import FormTemplate from './FormTemplate';
import { getAgenciesAction } from '../../../business/actions/agencyAction';
import { regexMobileNumber, renderSelectValues } from '../../../utils/utils';
import { getPhoneNumberAction, postPhoneNumberAction } from '../../../business/actions/mobileAction';
import { getOrdersAction } from '../../../business/actions/orderAction';
import { AuthContext } from '../../../utils/context/AuthContext';

const MobileForm = () => {
  const { agencies } = useSelector((state) => state.agencies);
  const { mobiles } = useSelector((state) => state.mobiles);
  const { orders } = useSelector((state) => state.orders);
  const { isPhoneNumberExist, message } = useSelector((state) => state.mobiles);
  const [isSubmitForm, setIsSubmitForm] = useState(false);
  const { token, role } = useContext(AuthContext);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const mobileAlreadyExistNotification = () => {
    notification.warning({
      description: t('shared.notification.mobile.mobileAlreadyExist'),
    });
  };

  useEffect(() => {
    dispatch(getAgenciesAction.request({ token, role }));
    dispatch(getOrdersAction.request({ token }));
  }, [mobiles]);

  useEffect(() => {
    if (!message && isSubmitForm) {
      form.resetFields();
      setIsSubmitForm(false);
    }
  }, [message, isSubmitForm]);

  useEffect(() => {
    if (isPhoneNumberExist) {
      mobileAlreadyExistNotification();
    }
  }, [isPhoneNumberExist]);

  const onFinish = (data) => {
    const resultPromise = [];
    const {
      agencyId, telephoneNumber, orderId, email,
    } = data;
    if (!isPhoneNumberExist) {
      resultPromise.push(dispatch(postPhoneNumberAction.request({
        orderId,
        email,
        telephoneNumber,
        agencyId,
        androidId: '',
        calibrationSoundCount: 0,
        model: '',
        systemVersion: '',
        token,
      })));
      setIsSubmitForm(true);
      return Promise.all(resultPromise);
    }
    mobileAlreadyExistNotification();
    return Promise.reject(new Error('Error'));
  };

  const handleInputMobileChange = (event) => {
    const { value } = event.target;
    if (value.length === 10) {
      dispatch(getPhoneNumberAction.request({ telephoneNumber: value, token }));
    }
  };

  const isOrderDisabled = (order) => order?.licences?.length >= order.licenceLimit;

  const variables = [
    {
      label: t('settings.mobile.order'),
      name: 'orderId',
      type: 'select',
      selectValues: renderSelectValues(orders, isOrderDisabled),
      placeholder: t('settings.mobile.placeholderMobileOrder'),
      help: t('settings.mobile.orderNameHelp'),
      rules: [
        {
          required: true,
          message: t('settings.mobile.orderHelp'),
        },
      ],
    },
    {
      label: t('settings.mobile.mobileNumber'),
      name: 'telephoneNumber',
      prefix: <MobileOutlined />,
      maxLength: 10,
      onChange: (event) => handleInputMobileChange(event),
      rules: [
        {
          required: true,
          pattern: regexMobileNumber,
          message: t('settings.mobile.mobileNumberHelp'),
        },
      ],
      placeholder: t('settings.mobile.placeholderMobileNumber'),
    },
    {
      label: t('settings.mobile.email'),
      name: 'email',
      prefix: <MailOutlined />,
      placeholder: t('settings.mobile.placeholderEmail'),
      rules: [
        {
          type: 'email',
        },
      ],

    },
    {
      label: t('settings.mobile.agency'),
      name: 'agencyId',
      type: 'select',
      selectValues: renderSelectValues(agencies),
      placeholder: t('settings.mobile.placeholderAgency'),
    },
  ];

  return (
    <FormTemplate
      nameForm="mobile-form"
      className="settings-form"
      useForm={form}
      variables={variables}
      buttonValidText={t('shared.formTemplate.submit')}
      onFinishApiCall={onFinish}
    />
  );
};

export default MobileForm;
