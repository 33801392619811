import api from '../utils/query';

const baseUrl = 'user';

const getUsersService = (payload) => {
  const { token, id } = payload;
  const urlAPI = id ? `${baseUrl}/${id}` : `${baseUrl}/all`;
  return api.get(urlAPI, token);
};

export default getUsersService;
