import React from 'react';
import MobileForm from '../shared/Forms/MobileForm';
import SettingsContainer from './SettingsContainer';

const MobileSettings = () => (
  <SettingsContainer>
    <MobileForm />
  </SettingsContainer>

);

export default MobileSettings;
