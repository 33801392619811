import React, { useContext, useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';
import { Layout, Skeleton } from 'antd';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Aside from '../Aside';
import NotAuthorizedAccess from '../NotAuthorizedAccess';
import HeaderContent from '../headerContent';
import { AuthContext } from '../../../utils/context/AuthContext';

const { Content } = Layout;
const rolePermissions = {
  1: ['/dashboard', '/supervision', '/orders', '/settings', '/sounds'],
  2: ['/dashboard', '/supervision', '/orders', '/sounds'],
  3: ['/dashboard', '/supervision', '/sounds'],
  4: ['/dashboard', '/supervision', '/sounds'],
};

export const hasAccess = (role, path) => {
  const allowedPaths = rolePermissions[role] || [];

  return allowedPaths.some((allowedPath) => path.startsWith(allowedPath));
};

const PrivateRoute = ({ children }) => {
  const { token, loading } = useSelector((state) => state.auth);
  const { distributors } = useSelector((state) => state.distributors);
  const { companies } = useSelector((state) => state.companies);
  const { agencies } = useSelector((state) => state.agencies);
  const { users } = useSelector((state) => state.users);
  const { role } = useContext(AuthContext);
  const [entityName, setEntityName] = useState('');
  const [title, setTitle] = useState('--');

  const { t } = useTranslation();
  const location = useLocation();
  const { pathname } = location;
  const userHasAccess = hasAccess(role, pathname);

  useEffect(() => {
    const entities = {
      agency: agencies,
      company: companies,
      distributor: distributors,
      chief: distributors,
    };

    const selectedEntity = entities[users.role]?.[0]?.name;

    if (selectedEntity) {
      setEntityName(selectedEntity);
    }
  }, [agencies, companies, distributors]);

  useEffect(() => {
    if (entityName) {
      const textTitle = t(`headerContent.${users.role}`) + entityName;
      setTitle(textTitle);
    }
  }, [entityName]);

  if (!token?.access_token) {
    return (
      <NotAuthorizedAccess
        token={token}
        reason={t('shared.authorization.notLogged')}
        redirectText={t('shared.authorization.redirectToLogin')}
        buttonText={t('shared.authorization.btnToLogin')}
        linkTo="/"
      />
    );
  }

  const content = userHasAccess
    ? children
    : (
      <NotAuthorizedAccess
        token={token}
        reason={t('shared.authorization.notAuthorized')}
        redirectText={t('shared.authorization.redirectToDashboard')}
        buttonText={t('shared.authorization.btnToDashboard')}
        linkTo="/dashboard"
      />
    );

  return (
    <Layout>
      <Aside />
      <Layout>
        <HeaderContent title={title} />
        <Content>
          {loading ? <Skeleton /> : content }
        </Content>
      </Layout>
    </Layout>
  );
};

PrivateRoute.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PrivateRoute;
