import { notification, Upload } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import UploadButton from './UploadButton';
import PreviewImage from './PreviewImage';

const checkImageDimensions = (file) => new Promise((resolve, reject) => {
  const img = new Image();
  img.src = URL.createObjectURL(file);

  img.onload = () => resolve(img.width <= 188 && img.height <= 60);
  img.onerror = () => reject(new Error('Image dimensions check failed'));
});

const checkFileSize = (file) => {
  const maxFileSize2MO = 2 * 1024 * 1024;
  return file.size < maxFileSize2MO;
};

const checkFileFormat = (file) => {
  const validFormats = ['image/png', 'image/jpeg'];
  return validFormats.includes(file.type);
};

const getBase64 = (file) => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = () => reject(new Error('Failed to convert file to Base64.'));
});

const notifyError = (message) => notification.warning({ description: message });

const validateFile = async (file, t) => {
  const isValidFileFormat = checkFileFormat(file);
  if (!isValidFileFormat) {
    notifyError(t('shared.notification.uploadImage.invalidFormat'));
    return false;
  }

  const isValidFileSize = checkFileSize(file);
  if (!isValidFileSize) {
    notifyError(t('shared.notification.uploadImage.invalidSize'));
    return false;
  }

  const isDimensionsValid = await checkImageDimensions(file);
  if (!isDimensionsValid) {
    notifyError(t('shared.notification.uploadImage.invalidDimensions'));
    return false;
  }

  return true;
};

const updateFileList = async (file, setFileList) => {
  const thumbUrl = await getBase64(file);
  setFileList([
    {
      id: file.uid,
      name: file.name,
      status: 'done',
      thumbUrl,
    },
  ]);
};

const UploadImage = ({ fileList, setFileList }) => {
  const [previewImage, setPreviewImage] = useState('');
  const [previewOpen, setPreviewOpen] = useState(false);
  const { t } = useTranslation();

  const beforeUpload = async (file) => {
    const isValid = await validateFile(file, t);
    if (isValid) {
      await updateFileList(file, setFileList);
    }
    return false;
  };

  const handleRemove = () => setFileList([]);

  const handlePreview = async (file) => {
    setPreviewImage(file.thumbUrl || file.url);
    setPreviewOpen(true);
  };

  return (
    <>
      <Upload
        listType="picture-card"
        fileList={fileList}
        beforeUpload={beforeUpload}
        onPreview={handlePreview}
        onRemove={handleRemove}
        showUploadList={{
          showPreviewIcon: true,
          showRemoveIcon: true,
        }}
        maxCount={1}
      >
        <UploadButton />
      </Upload>

      {previewImage && (
      <PreviewImage
        previewOpen={previewOpen}
        previewImage={previewImage}
        setPreviewOpen={setPreviewOpen}
        setPreviewImage={setPreviewImage}
      />
      )}
    </>
  );
};

UploadImage.propTypes = {
  fileList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setFileList: PropTypes.func.isRequired,
};

export default UploadImage;
