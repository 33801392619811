import { Image } from 'antd';
import PropTypes from 'prop-types';

const PreviewImage = ({
  previewOpen,
  previewImage,
  setPreviewOpen,
  setPreviewImage,
}) => (
  <Image
    preview={{
      visible: previewOpen,
      onVisibleChange: (visible) => setPreviewOpen(visible),
      afterOpenChange: (visible) => !visible && setPreviewImage(''),
    }}
    src={previewImage}
  />
);

PreviewImage.propTypes = {
  previewOpen: PropTypes.bool.isRequired,
  previewImage: PropTypes.string.isRequired,
  setPreviewOpen: PropTypes.func.isRequired,
  setPreviewImage: PropTypes.func.isRequired,
};

export default PreviewImage;
