import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { postDistributorAction } from '../../../business/actions/distributorAction';
import EntityFormTemplate from './EntityFormTemplate';

const DistributorForm = () => {
  const [fileList, setFileList] = useState([]);
  const { t } = useTranslation();

  const uploadFileField = [{
    label: t('settings.distributor.downloadLogo'),
    name: 'logoImage',
    type: 'uploadImage',
    fileList,
    setFileList,
  }];

  return (
    <EntityFormTemplate
      fileList={fileList}
      setFileList={setFileList}
      uploadFileField={uploadFileField}
      type="distributor"
      postAction={postDistributorAction}
    />
  );
};
export default DistributorForm;
