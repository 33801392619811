import { call, put, takeEvery } from 'redux-saga/effects';
import { getUsersAction, USERS } from '../actions/userAction';
import getUsersService from '../services/userService';

export function* getUsers({ payload }) {
  const { token } = payload;

  try {
    const users = yield call(getUsersService, { token });
    const data = users?.data;
    if (data) {
      yield put(getUsersAction.success(data));
    }
  } catch (err) {
    yield put(getUsersAction.failure(err));
  }
}

export function* usersSaga() {
  yield takeEvery(USERS.FETCH.REQUEST, getUsers);
}
