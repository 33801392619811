import React from 'react';
import CanalForm from '../shared/Forms/CanalForm';
import SettingsContainer from './SettingsContainer';

const CanalSettings = () => (
  <SettingsContainer>
    <CanalForm />
  </SettingsContainer>

);

export default CanalSettings;
