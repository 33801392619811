import { Divider } from 'antd';
import React from 'react';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

const SettingsContainer = ({ children }) => {
  const navigate = useNavigate();

  return (
    <>
      <ArrowLeftOutlined className="come-back" onClick={() => navigate(-1)} />
      <Divider />
      {children}
    </>
  );
};

SettingsContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SettingsContainer;
