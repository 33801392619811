import React, { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { EditOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { Button, Form, notification } from 'antd';
import PropTypes from 'prop-types';
import { AuthContext } from '../../../utils/context/AuthContext';
import { preventInvalidNumberInput, renderEmails } from '../../../utils/utils';
import ActionButton from '../ActionButton';
import DashboardList from '../SharedStyle';
import HeaderList from '../../Dashboard/agenciesDetails/HeaderList';
import TableTemplate from '../TableTemplate';
import EditableCell from '../EditableCell';

const EntityListTemplate = ({
  type,
  entity,
  addColumn,
  patchAction,
  updateAction,
}) => {
  const [editingKey, setEditingKey] = useState('');
  const isEditingRow = (record) => record.key === editingKey;
  const [tableData, setTableData] = useState([]);
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { token, role } = useContext(AuthContext);
  const dispatch = useDispatch();
  const headerListTitlte = role === 2 && type === 'distributors' ? 'dashboard.distributors.infoTitle' : `dashboard.${type}.title`;

  const onEdit = (record) => {
    form.setFieldsValue({
      name: record.name,
      emails: record.emails,
      dailyMailHour: record.dailyMailHour,
      dailyMailMinute: record.dailyMailMinute,
      ...record,
    });
    setEditingKey(record.key);
  };

  const uniqueName = (data, name) => {
    const normalizedName = name.toLowerCase();
    return data.filter((item) => item.name.toLowerCase() === normalizedName).length === 1;
  };

  const updateTableData = (key, row) => {
    const newData = [...tableData];
    const index = newData.findIndex((item) => key === item.key);

    if (index > -1) {
      const item = newData[index];
      newData.splice(index, 1, { ...item, ...row });
    } else {
      newData.push(row);
    }

    return newData;
  };

  const handleUpdateRequest = async (key, row) => {
    const currentEntity = entity.find((item) => item.id === key);
    const requestCommunData = {
      name: row.name,
      emails: currentEntity.emails,
      dailyMailHour: row.dailyMailHour,
      dailyMailMinute: row.dailyMailMinute,
      id: currentEntity.id,
      token,
      role,
    };
    const additionalData = (type !== 'agency') ? { logoImage: '' } : { companyId: currentEntity.companyId };
    const requestData = { ...requestCommunData, ...additionalData };
    return dispatch(patchAction.request({ ...requestData }));
  };

  const save = async (key) => {
    try {
      const row = await form.validateFields();
      const updatedData = updateTableData(key, row);

      const isUniquementName = uniqueName(updatedData, row.name);
      if (!isUniquementName) {
        notification.warning({
          description: t(`shared.notification.${type}.alreadyExist`),
        });
        return;
      }

      setTableData(updatedData);
      await handleUpdateRequest(key, row);

      setEditingKey('');
    } catch (errInfo) {
      setEditingKey(errInfo);
    }
  };

  const renderData = () => {
    const data = [];
    entity?.map((item) => {
      const {
        id, name, emails, dailyMailHour, dailyMailMinute, companyId,
      } = item;
      data.push({
        key: id,
        name,
        associatedAgencies: id,
        associatedCompanies: id,
        company: companyId,
        emails,
        dailyMailHour,
        dailyMailMinute,
      });
      return null;
    });
    setTableData(data);
  };

  useEffect(() => {
    renderData();
  }, [entity]);

  const onCancel = () => {
    setEditingKey('');
  };

  const columns = [
    {
      title: t('dashboard.company.name'),
      dataIndex: 'name',
      key: 'name',
      responsive: ['md', 'lg'],
      width: 90,
      editable: true,
    },
    addColumn,
    {
      title: 'emails',
      dataIndex: 'emails',
      key: 'emails',
      render: (text) => text?.map((item, index) => renderEmails(item, index)),
      responsive: ['md', 'lg'],
      width: 80,
    },
    {
      title: t('dashboard.company.dailyMailHour'),
      dataIndex: 'dailyMailHour',
      key: 'dailyMailHour',
      responsive: ['md', 'lg'],
      align: 'center',
      type: 'number',
      width: 38,
      min: 0,
      max: 23,
      onBeforeInput: (event) => preventInvalidNumberInput(event, 0, 23),
      editable: true,
    },
    {
      title: t('dashboard.company.dailyMailMinute'),
      dataIndex: 'dailyMailMinute',
      key: 'dailyMailMinute',
      responsive: ['md', 'lg'],
      align: 'center',
      type: 'number',
      width: 38,
      min: 0,
      max: 59,
      onBeforeInput: (event) => preventInvalidNumberInput(event, 0, 59),
      editable: true,
    },
    {
      title: t('shared.tableTemplate.action.title'),
      key: 'action',
      dataIndex: 'action',
      responsive: ['md', 'lg'],
      width: 35,
      align: 'center',
      render: (_, record) => {
        const editable = isEditingRow(record);
        return editable ? (
          <>
            <Button
              type="primary"
              ghost
              className="button-reset"
              onClick={() => save(record.key)}
            >
              {t('shared.formTemplate.submit')}
            </Button>
            <Button danger className="button-reset" onClick={onCancel}>
              {t('shared.formTemplate.cancel')}
            </Button>
          </>
        ) : (
          <ActionButton
            title={t('shared.tableTemplate.action.edit')}
            disabled={editingKey !== ''}
            icon={<EditOutlined className="blue-azure" />}
            onClick={() => onEdit(record)}
          />
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditingRow(record),
        onBeforeInput: col.onBeforeInput,
        type: col.type,
        max: col.max,
        min: col.min,
      }),
    };
  });

  return (
    <DashboardList>
      <HeaderList
        name={t(headerListTitlte)}
        stateToUpdate={updateAction}
        visibleUpdateData
      />
      <Form form={form} component={false}>
        <TableTemplate
          columns={mergedColumns}
          data={tableData}
          components={{
            body: {
              cell: EditableCell,
            },
          }}
        />
      </Form>
    </DashboardList>
  );
};

EntityListTemplate.propTypes = {
  type: PropTypes.string.isRequired,
  entity: PropTypes.arrayOf(PropTypes.shape({})),
  addColumn: PropTypes.shape({}).isRequired,
  patchAction: PropTypes.func.isRequired,
  updateAction: PropTypes.func.isRequired,
};

EntityListTemplate.defaultProps = {
  entity: null,
};

export default EntityListTemplate;
