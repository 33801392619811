import React from 'react';
import DistributorForm from '../shared/Forms/DistributorForm';
import SettingsContainer from './SettingsContainer';

const DistributorSettings = () => (
  <SettingsContainer>
    <DistributorForm />
  </SettingsContainer>

);

export default DistributorSettings;
