import { call, put, takeEvery } from 'redux-saga/effects';
import {
  AGENCIES,
  AGENCIESDETAILS,
  AGENCY,
  getAgenciesAction,
  getAgenciesDetailsAction,
  patchAgencyAction,
  postAgencyAction,
} from '../actions/agencyAction';
import {
  postAgencyService, getAgenciesService, patchAgencyService, getAgenciesByUserService,
} from '../services/agencyService';
import { setArrayOfUniqueItems } from '../../utils/utils';
import getUsersService from '../services/userService';
import { getUsersAction } from '../actions/userAction';

export const selectGetAgenciesService = (role) => (
  role !== 1 ? getAgenciesByUserService : getAgenciesService
);

export const getAgenciesDataChart = ({ uniqueAgencies, payload }) => {
  const result = [];

  uniqueAgencies.forEach((agency) => {
    const agencyData = payload.filter((item) => item.agencyName === agency);
    const totalDecision1 = agencyData.filter((item) => item.decision === 1).length;
    const totalDecision0and4 = agencyData
      .filter((item) => item.decision === 0 || item.decision === 4).length;
    const resultData = {
      name: agency,
      chartData: [totalDecision1, totalDecision0and4],
    };
    result.push(resultData);
  });
  return result;
};

export function* getAgencies({ payload }) {
  const { token, role } = payload;
  const service = selectGetAgenciesService(role);

  try {
    const agencies = yield call(service, token);
    const data = agencies?.data;
    if (data) {
      yield put(getAgenciesAction.success(agencies.data));
    }
  } catch (err) {
    yield put(getAgenciesAction.failure(err));
  }
}

export function* getAgenciesDetails({ payload }) {
  const uniqueAgencies = setArrayOfUniqueItems({ data: payload, type: 'agencies' });
  const data = getAgenciesDataChart({ uniqueAgencies, payload });

  try {
    yield put(getAgenciesDetailsAction.success({ chartData: data }));
  } catch (error) {
    yield put(getAgenciesDetailsAction.failure(error));
  }
}

export function* postAgency({ payload }) {
  const { token, role } = payload;
  const getService = selectGetAgenciesService(role);
  try {
    const agencyResponse = yield call(postAgencyService, payload);
    const agencies = yield call(getService, token);
    const users = yield call(getUsersService, { token });

    const agencyExist = agencies.data.some(
      (agency) => agency.name === payload.name,
    );

    if (agencyResponse && agencyExist) {
      const descriptionSuccess = 'shared.notification.agency.successAdd';
      yield put(getAgenciesAction.success(agencies.data));
      yield put(getUsersAction.success(users.data));
      yield put(postAgencyAction.success(descriptionSuccess));
      yield put(postAgencyAction.failure(null));
    }
  } catch (err) {
    yield put(postAgencyAction.failure(err));
  }
}

export function* patchAgency({ payload }) {
  const { token, role } = payload;
  const getService = selectGetAgenciesService(role);
  try {
    const agencyResponse = yield call(patchAgencyService, payload);
    if (agencyResponse) {
      const descriptionSuccess = 'shared.notification.agency.successUpdate';
      yield put(patchAgencyAction.success(descriptionSuccess));
      const newAgencies = yield call(getService, token);
      yield put(getAgenciesAction.success(newAgencies.data));
    }
  } catch (err) {
    yield put(patchAgencyAction.failure(err));
  }
}

export function* agenciesSaga() {
  yield takeEvery(AGENCIES.FETCH.REQUEST, getAgencies);
  yield takeEvery(AGENCIESDETAILS.FETCH.REQUEST, getAgenciesDetails);
  yield takeEvery(AGENCY.POST.REQUEST, postAgency);
  yield takeEvery(AGENCY.PATCH.REQUEST, patchAgency);
}
