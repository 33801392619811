import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import TableTemplate from '../shared/TableTemplate';
import DashboardList from '../shared/SharedStyle';
import HeaderList from './agenciesDetails/HeaderList';
import { getAnalysisAction } from '../../business/actions/analysisAction';

const TripletStats = () => {
  const { analysis } = useSelector((state) => state.analysis);
  const { t } = useTranslation();

  const newData = [];
  const newDataAnalysis = analysis?.filter((item) => (item.qualification < 2));

  const renderFilterData = (dpsg, dpsb, dcnn) => newDataAnalysis?.filter((item) => (
    item.decisionPeakSearchGlobal === dpsg
    && item.decisionPeakSearchBlock === dpsb
    && item.decisionCnn === dcnn
  ));

  const newDataLeak000 = renderFilterData(0, 0, 0);
  const newDataLeak100 = renderFilterData(1, 0, 0);
  const newDataLeak010 = renderFilterData(0, 1, 0);
  const newDataLeak001 = renderFilterData(0, 0, 1);
  const newDataLeak110 = renderFilterData(1, 1, 0);
  const newDataLeak101 = renderFilterData(1, 0, 1);
  const newDataLeak011 = renderFilterData(0, 1, 1);
  const newDataLeak111 = renderFilterData(1, 1, 1);

  const analysisDetails = [
    { name: 'T000', data: newDataLeak000 },
    { name: 'T100', data: newDataLeak100 },
    { name: 'T010', data: newDataLeak010 },
    { name: 'T001', data: newDataLeak001 },
    { name: 'T110', data: newDataLeak110 },
    { name: 'T101', data: newDataLeak101 },
    { name: 'T011', data: newDataLeak011 },
    { name: 'T111', data: newDataLeak111 },
  ];

  analysisDetails?.map(({ name, data }) => {
    const leakItem = data?.filter((elt) => elt.qualification === 1);
    const noLeakItem = data?.filter((elt) => elt.qualification === 0);
    const maxItem = Math.max(leakItem?.length, noLeakItem?.length);
    const percentage = data && Math.round((maxItem / data.length) * 100);

    newData.push({
      key: name,
      name,
      QF: leakItem?.length,
      QNF: noLeakItem?.length,
      Total: data?.length,
      percent: percentage || 0,
    });
    return null;
  });

  const columns = [
    {
      title: 'Triplet',
      dataIndex: 'name',
      key: 'name',
      align: 'center',
    },
    {
      title: 'QF',
      dataIndex: 'QF',
      key: 'QF',
      align: 'center',
    },
    {
      title: 'QNF',
      dataIndex: 'QNF',
      key: 'QNF',
      align: 'center',
    },
    {
      title: 'Total',
      dataIndex: 'Total',
      key: 'Total',
      align: 'center',
    },
    {
      title: '%',
      dataIndex: 'percent',
      key: 'percent',
      align: 'center',
    },
  ];

  return (
    <DashboardList width="450px">
      <HeaderList
        name={t('dashboard.triplets.title')}
        stateToUpdate={getAnalysisAction}
        visibleUpdateData
      />
      <TableTemplate data={newData} columns={columns} />
    </DashboardList>
  );
};

export default TripletStats;
