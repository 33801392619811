import React from 'react';
import CompanyForm from '../shared/Forms/CompanyForm';
import SettingsContainer from './SettingsContainer';

const CompanySettings = () => (
  <SettingsContainer>
    <CompanyForm />
  </SettingsContainer>

);

export default CompanySettings;
