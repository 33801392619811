import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import { persistStore, persistReducer } from 'redux-persist';
import localStorage from 'redux-persist/es/storage';
import rootSaga from '../sagas/rootSaga';
import rootReducer from '../reducers/rootReducer';
import tokenMiddleware from './tokenMiddleware';

const persistConfig = {
  key: 'root',
  storage: localStorage,
};
const sagaMiddleware = createSagaMiddleware();
const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: [sagaMiddleware, tokenMiddleware],
});

sagaMiddleware.run(rootSaga);

export const persistor = persistStore(store);

export default store;
