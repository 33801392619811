import React, { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Spin } from 'antd';
import LastListen from '../shared/LastListen';
import { getPhonesNumberAction } from '../../business/actions/mobileAction';
import { getAgenciesAction } from '../../business/actions/agencyAction';
import { getCompaniesAction } from '../../business/actions/companyAction';
import { getMonitorAction } from '../../business/actions/monitoringAction';
import ProgressBar from '../shared/ProgressBar';
import { getAnalysisAction } from '../../business/actions/analysisAction';
import IndicatorCards from '../shared/cards/indicators';
import CounterCards from '../shared/cards/counters';
import Charts from './charts';
import LastRecordInfos from './LastRecordInfos';
import { getRecordsAction } from '../../business/actions/recordsAction';
import { AuthContext } from '../../utils/context/AuthContext';

const Monitoring = () => {
  const { lastRecord, recordsList, loading: recordsLoading } = useSelector(
    (state) => state.rawRecords,
  );
  const { loading: agencyLoading, agencies } = useSelector((state) => state.agencies);
  const { mobiles } = useSelector((state) => state.mobiles);
  const { loading: analysisLoading, analysis } = useSelector((state) => state.analysis);
  const { loading: companyLoading } = useSelector((state) => state.companies);
  const { loading: mobileLoading } = useSelector((state) => state.mobiles);
  const isLoading = analysisLoading || agencyLoading || companyLoading || mobileLoading
  || recordsLoading;
  const { token, role } = useContext(AuthContext);
  const dispatch = useDispatch();

  const getLastRecordInfos = () => {
    if (lastRecord) {
      const { timestamp, numberMobile } = lastRecord;
      const recordInfo = analysis?.find((analyse) => analyse?.timeStamp === timestamp);

      if (recordInfo) {
        return recordInfo;
      }
      const agencyId = mobiles?.find((mobile) => mobile?.telephoneNumber === numberMobile);
      if (agencyId) {
        const hasAgency = agencies?.find((agency) => agency?.id === agencyId?.agencyId);
        const agencyName = hasAgency?.name;
        return {
          ...lastRecord,
          agency: agencyName || '--',
        };
      }
    }
    return null;
  };

  const lastRecordinfos = getLastRecordInfos();

  useEffect(() => {
    dispatch(getMonitorAction.request({ soundsList: analysis, recordsList }));
  }, [analysis]);

  useEffect(() => {
    if (!isLoading) {
      const refreshData = setInterval(() => {
        dispatch(getAnalysisAction.request({ token, role }));
        dispatch(getRecordsAction.request({ token, role }));
        dispatch(getAgenciesAction.request({ token, role }));
        dispatch(getCompaniesAction.request({ token, role }));
        if (agencies) {
          dispatch(getPhonesNumberAction.request({ agencies, token, role }));
        }
      }, 62000);

      return () => {
        clearInterval(refreshData);
      };
    }
    return undefined;
  }, [isLoading, agencies]);

  return (
    <>
      {!isLoading ? <ProgressBar /> : <Spin className="position-absolute" />}
      <LastListen lastListen={lastRecordinfos?.timeStamp} />
      <section className="monitoring">
        <div className="monitoring-left-block">
          <IndicatorCards />
          <Charts />
        </div>
        <div className="monitoring-right-block">
          { lastRecordinfos && (
          <LastRecordInfos
            lastRecordInfos={lastRecordinfos}
            mapData={[lastRecordinfos]}
          />
          )}
          <CounterCards />
        </div>
      </section>
    </>
  );
};

export default Monitoring;
