import React, {
  useContext, useEffect, useState,
} from 'react';
import {
  AppstoreOutlined,
  AudioOutlined,
  RadarChartOutlined,
  SettingOutlined,
  ShoppingCartOutlined,
} from '@ant-design/icons';
import { Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { MenuComponent, SiderComponent } from './SharedStyle';
import logo from '../../assets/images/leaktracker-logo.png';
import { AuthContext } from '../../utils/context/AuthContext';

const appVersion = process.env.REACT_APP_VERSION;

const Aside = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const [logoEntity, setLogoEntity] = useState();
  const selectedKeys = location.pathname.split('/')[1];
  const { role } = useContext(AuthContext);
  const { distributors } = useSelector((state) => state.distributors);
  const { mobiles } = useSelector((state) => state.mobiles);

  useEffect(() => {
    if (distributors && distributors.length > 0) {
      const logoImage = distributors[0]?.logoImage;
      setLogoEntity(logoImage);
    } else if (mobiles && mobiles.length > 0) {
      const logoImage = mobiles[0]?.distributorLogo;
      setLogoEntity(logoImage);
    }
  }, [mobiles, distributors]);

  const getItem = (label, key, icon, children) => ({
    key,
    icon,
    children,
    label,
  });

  const items = [
    getItem(
      <Link className="item-link" to="/dashboard">{t('aside.dashboard')}</Link>,
      'dashboard',
      <AppstoreOutlined data-testid="dashboard-icon" />,
    ),
    getItem(
      <Link className="item-link" to="/supervision">{t('aside.monitoring')}</Link>,
      'supervision',
      <RadarChartOutlined data-testid="supervision-icon" />,
    ),
    getItem(
      <Link className="item-link" to="/sounds">{t('aside.records')}</Link>,
      'sounds',
      <AudioOutlined data-testid="audio-icon" />,
    ),
    role === 1 || role === 2 ? getItem(
      <Link className="item-link" to="/orders">{t('aside.orders')}</Link>,
      'orders',
      <ShoppingCartOutlined data-testid="orders-icon" />,
    ) : null,
    role === 1 ? getItem(
      <Link className="item-link" to="/settings">{t('aside.settings')}</Link>,
      'settings',
      <SettingOutlined data-testid="settings-icon" />,
    ) : null,
  ].filter((item) => item !== null);

  return (
    <SiderComponent collapsedWidth={80} width={240}>
      <Link className="aside-header" to="/dashboard">
        <img className="aside-logo" src={logo} alt="Leak Tracker logo" />
      </Link>
      <p className="align-center">
        {appVersion}
      </p>
      {logoEntity && <p className="align-center"><img src={logoEntity} alt="logo" /></p>}
      <MenuComponent
        selectedKeys={[selectedKeys]}
        mode="inline"
        items={items}
      />
    </SiderComponent>
  );
};

export default Aside;
