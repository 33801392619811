import React from 'react';
import AgencyForm from '../shared/Forms/AgencyForm';
import SettingsContainer from './SettingsContainer';

const AgencySettings = () => (
  <SettingsContainer>
    <AgencyForm />
  </SettingsContainer>

);

export default AgencySettings;
